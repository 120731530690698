import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, Grid, Card, CardActionArea,CardContent,TextField,CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSearchParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import PostEditor from './PostEditor';
import RatingCard from '../../common/components/RatingCard';
import LocationSelector from '../../common/components/LocationSelector';
import LocationCard from '../../common/components/LocationCard';
import { clearLocationFromEditor, updateEditorAtIndex, clearRatingFromEditor,updateEditorArtAtIndex } from '../../repository/posts/postsSlice';
import { postGemini } from '../../api/SwissApi';
import QuillEditor from '../../common/components/editor/QuillEditor';
const PhotoSelector = ({onClick}) =>{
  return (    
    <Grid container >
        <Grid item>
          <Card sx={{ 
            boxShadow: 'none',
            border: 0,    
            '&:hover': {
              // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Example hover effect
              borderRadius: '12px', // Increase border radius on hover
            }
            }}> 
            <CardActionArea onClick={onClick}>
              <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth:'323px', height:'323px', padding:0, backgroundColor:'#F2F4F5', borderRadius:'12px' }}>
                <img src='/assets/icons/camera.png' alt={'camera'} style={{ width: 24, height: 24, marginBottom: 4}}/>
                <Typography sx={{ fontSize: '16px', fontWeight:600, color:'rgba(0, 0, 0, 0.70)'}}>
                  사진 추가
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
    </Grid>
  );
}

const PostListEditForm = ({ handleRemoveImage, handlePhotoEdit,loading }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSlideIndex = parseInt(searchParams.get('content_index') || 0);
  const dispatch = useDispatch();

  const [currentSlide, setCurrentSlide] = useState(0);
  const editors = useSelector(state => state.posts.editors);  // Redux 스토어에서 editors 배열을 가져옵니다.

  const [slides, setSlides] = useState([]);

  const swiperRef = useRef(null); // Swiper 인스턴스를 위한 ref 생성
  
  useEffect(() => {
    console.log('initialSlideIndex', initialSlideIndex)
    setCurrentSlide(initialSlideIndex);

    // Set the initial slide based on the URL parameter at component mount
    const swiperInstance = swiperRef.current?.swiper;
    setTimeout(() => {
      swiperInstance.slideTo(initialSlideIndex, 0);
    }, 100); // Delaying the slide transition
  }, [initialSlideIndex]);

  useEffect(() => {
    setSlides(editors);
  }, [editors]); // editors 배열이 변경될 때마다 이 effect 실행

  return (
    <Box sx={{ position: 'relative' }}>
      {/* 로딩 시 전체 컨텐츠 불투명 처리 */}
      <Box sx={{ opacity: loading ? 0.5 : 1 }}>
      {/* <Box sx={{
        display: 'flex',  // Flexbox 레이아웃 사용
        justifyContent: 'center',  // 가로 방향 중앙 정렬
        alignItems: 'center',  // 세로 방향 중앙 정렬
        backgroundColor: 'rgba(58,139,255,1)',
        width: 54, height: 28, borderRadius: '8px',
      }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 600, color: 'rgba(255,255,255,1)' }}>
          {currentSlide + 1} / 10
        </Typography>
      </Box> */}
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        // slidesPerView={1.1} // Shows the current slide and a little of the next one
        slidesPerView={1} // Shows the current slide and a little of the next one
        centeredSlides={true} // Optionally center slides
        initialSlide={initialSlideIndex}
        onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)} // 슬라이드가 변경될 때 인덱스 업데이트
        allowSlideNext={false}  // 이후 슬라이드 이동 비활성화
        allowSlidePrev={false}  // 이전 슬라이드로 이동 비활성화

      >
        {slides.map((editor, index) => {
          const isLastSlide = index === slides.length - 1;
          const { image_url, location: locationValue, rating: ratingValue } = editor;
          if ((isLastSlide && slides.length < 10) || (isLastSlide && slides.length == 10 && image_url == "")) {
            return <SwiperSlide key={index}>
              <PhotoSelector onClick={() => { handlePhotoEdit('add') }} />
              <Box sx={{ height: 10 }} />
              {/* <PostEditor type='photo' contentIndex={index} content={editors[index]?.content} handleChange={(value) => (dispatch(updateEditorAtIndex({ index: index, content: value })))}/> */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p:0 }}>
                <Typography variant="body2">
                  <span style={{ color: (editors[index]?.content?.length || 0) > 1000 ? '#ff0000' : 'rgba(0, 0, 0, 0.6)' }}>
                    {editors[index]?.content?.length || 0}
                  </span>
                  <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>/1000</span>
                </Typography>
              </Box>
              <QuillEditor initialValue={editors[index]?.rich_content} onChange={(value)=>(dispatch(updateEditorAtIndex({index:index, content:value?.content, rich_content:value?.rich_content, hashtags:value?.hashtags})))}/>
              <Box sx={{ height: 30 }} />
              <Box sx={{ width: '323px' }}>
                <>
                  {locationValue?.name ? (
                    <Box>
                      <LocationCard location={locationValue} handleClose={() => { dispatch(clearLocationFromEditor({ index: index })) }} />
                      <Box sx={{ marginBottom: '12px' }} />
                    </Box>
                  ) : (
                    <Box>
                    <LocationSelector contentIndex={index} />
                    <Box sx={{ marginBottom: '12px' }} />
                  </Box>
                  )
                  }
                </>
                <RatingCard
                        // placeName={locationValue?.name}
                        value={ratingValue}
                        handleChange={(newValue) => dispatch(updateEditorAtIndex({ index: index, rating: newValue }))}
                        handleClose={() => dispatch(clearRatingFromEditor({ index: index }))}
                      />
                      <Box sx={{ marginBottom: '10px' }} />
              </Box>
            </SwiperSlide>
          }
          else {
            return <SwiperSlide key={index}>
              <Box sx={{ height: 10 }} />
              <Box>
                <Box sx={{ position: 'relative', width: 'calc(100% - 10px)', height: 'auto' }}>
                  <>
                    <img src={editor.image_url} alt={`Slide ${index}`} style={{ width: '100%', maxHeight: '350px' }} />
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      sx={{ position: 'absolute', top: 0, right: 0, color: 'white', width: 26, height: 26, backgroundColor: 'rgba(0,0,0,0.5)', '&:hover': { backgroundColor: 'rgba(0,0,0,0.8)' } }}
                    >
                      <CloseIcon sx={{ width: 18, height: 18, padding: 0, margin: 0 }} />
                    </IconButton>
                  </>
                </Box>
                <AIGeneratorInfo index={index} />
                <Box sx={{ height: 10 }} />
                {/* <PostEditor contentIndex={index} content={editors[index]?.content} handleChange={(value) => (dispatch(updateEditorAtIndex({ index: index, content: value })))}/> */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p:0 }}>
                  <Typography variant="body2">
                    <span style={{ color: (editors[index]?.content?.length || 0) > 1000 ? '#ff0000' : 'rgba(0, 0, 0, 0.6)' }}>
                      {editors[index]?.content?.length || 0}
                    </span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.6)' }}>/1000</span>
                  </Typography>
                </Box>
                <QuillEditor initialValue={editors[index]?.rich_content} onChange={(value)=>(dispatch(updateEditorAtIndex({index:index, content:value?.content, rich_content:value?.rich_content, hashtags:value?.hashtags})))}/>
                <Box sx={{ height: 30 }} />
                <>
                  {locationValue?.name ? (
                    <Box>
                      <LocationCard location={locationValue} handleClose={() => { dispatch(clearLocationFromEditor({ index: index })) }} />
                      <Box sx={{ marginBottom: '12px' }} />
                    </Box>
                  ) : (
                    <Box>
                      <LocationSelector contentIndex={index} />
                      <Box sx={{ marginBottom: '12px' }} />
                    </Box>
                  )
                  }
                </>
                <RatingCard
                    // placeName={locationValue?.name}
                    value={ratingValue}
                    handleChange={(newValue) => dispatch(updateEditorAtIndex({ index: index, rating: newValue }))}
                    handleClose={() => dispatch(clearRatingFromEditor({ index: index }))}
                />
                <Box sx={{ marginBottom: '10px' }} />
              </Box>
            </SwiperSlide>
          }
        })}
      </Swiper>
      </Box>
      {/* 로딩 상태에서 로딩 아이콘 표시 */}
      {loading && (
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default PostListEditForm;


const AIGeneratorInfo = ({index}) => {
  const dispatch = useDispatch();
  const editors = useSelector(state => state.posts.editors); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // const { image_url } = editors[index];
    // if (image_url) {
    //   setLoading(true);
    //   postGemini(image_url).then((response) => {
    //     console.log('response', response);
    //     dispatch(updateEditorArtAtIndex({ index: index, art: { 'title': response.title, 'artist': response.artist } }));
    //   }).finally(() => {
    //     setLoading(false);
    //   });
    // }
  }, [index]);

  return (
    <Box sx={{ position: 'relative', padding: '10px 4px' }}>
      <Box sx={{ opacity: loading ? 0.5 : 1 }}>
        <Box sx={{ textAlign: 'left' }}>
          <TextField
            required
            id="outlined-required"
            label="작품명"
            sx={{ width: '100%' }}
            value={editors[index]?.art?.title || ''}
            onChange={(e) => dispatch(updateEditorArtAtIndex({ index: index, art: { 'title': e.target.value, 'artist': editors[index]?.art?.artist } }))}
            variant="standard"
          />
        </Box>
        <Box sx={{ height: '10px' }} />
        <Box sx={{ textAlign: 'left' }}>
          <TextField
            required
            id="outlined-required"
            label="작가명"
            sx={{ width: '100%' }}
            value={editors[index]?.art?.artist || ''}
            onChange={(e) => dispatch(updateEditorArtAtIndex({ index: index, art: { 'title': editors[index]?.art?.title, 'artist': e.target.value } }))}
            variant="standard"
          />
        </Box>
      </Box>
      {loading && (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};