import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay } from 'swiper/modules';
import '../../App.css'; 
const StyledSwiper = {
  height: '106px',
  borderRadius: '30px', // Rounded corners with 8px radius
};
const CarouselBanner = () => {
  const handleBanner1Click = () => {
    window.open('https://forms.gle/wHRxf79WwFvLZsPK8', '_blank'); // 새 탭으로 링크 열기
  };

  const handleBanner2Click = () => {
    window.open('https://www.youtube.com/@artfrnds', '_blank'); // 새 탭으로 링크 열기
  }

  const handleBanner3Click = () => {
    window.open('https://cafe.naver.com/amateurmagician', '_blank'); // 새 탭으로 링크 열기
  }

  return (
    <>
      <Swiper
        slidesPerView={1.2}
        spaceBetween={10}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay]}
        className="mySwiper"
      >  
        <SwiperSlide style={StyledSwiper} onClick={handleBanner1Click}>
          <img
            src="assets/images/banner1.png"
            alt="banner1"
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
          />
        </SwiperSlide>
        <SwiperSlide style={StyledSwiper} onClick={handleBanner2Click}>
          <img
            src="assets/images/banner_youtube.png"
            alt="banner_youtube"
            style={{ width: '100%', height: '100%', cursor: 'pointer' }} 
          />
        </SwiperSlide>
        <SwiperSlide style={StyledSwiper} onClick={handleBanner1Click}>
          <img
            src="assets/images/banner1.png"
            alt="banner4"
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
          />
        </SwiperSlide>
        <SwiperSlide style={StyledSwiper} onClick={handleBanner3Click}>
          <img
            src="assets/images/banner_cafe.png"
            alt="banner_cafe"
            style={{ width: '100%', height: '100%', cursor: 'pointer' }}
          />
        </SwiperSlide>
    </Swiper>
    </>
  );
};

export default CarouselBanner;