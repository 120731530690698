import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnakeBarAlert } from '../../../common/hooks/useSnakeBarAlert';
import { postBookmarks, deleteBookmarks } from '../../../api/SwissApi';
import { fetchPostFeedLike, fetchDeleteFeedLike } from '../../../repository/posts/postsRepository';

export const usePostInteractions = (post) => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.userId);
  
  const swiperRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const [imageDetailOpen, setImageDetailOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isPostBookmarked, setIsPostBookmarked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);

  const { removeBookmarkAlert, addBookmarkAlert, handleLoginAlert, handleSecessionAlert } = useSnakeBarAlert();

  useEffect(() => {
    setIsLiked(post.is_liked);
    setIsPostBookmarked(post.is_posts_bookmarked);
    setLikesCount(post.likes_count);
    setCommentsCount(post.comments_count);
  }, [post]);

  const handleLike = async () => {
    if (!userId) {
        handleLoginAlert();
        return;
    }
    
    setIsLiked(!isLiked);
    try {
      if (isLiked) {
        setLikesCount(likesCount - 1);
        await fetchDeleteFeedLike(dispatch, post.id);
      } else {
        setLikesCount(likesCount + 1);
        await fetchPostFeedLike(dispatch, post.id);
      }
      return true;
    } catch (error) {
      setIsLiked(!isLiked); // Revert on error
      return false;
    }
  };

  const handleBookmark = async () => {
    if (!userId) {
        handleLoginAlert();
        return;
    }
    
    setIsPostBookmarked(!isPostBookmarked);
    try {
      if (!isPostBookmarked) {
        addBookmarkAlert();
        await postBookmarks('post', post.id);
      } else {
        removeBookmarkAlert();
        await deleteBookmarks('post', post.id);
      }
      return true;
    } catch (error) {
      setIsPostBookmarked(!isPostBookmarked); // Revert on error
      return false;
    }
  };

  return {
    swiperRef,
    currentSlide,
    setCurrentSlide,
    isLiked,
    isPostBookmarked,
    handleLike,
    handleBookmark,
    likesCount,
    setLikesCount,
    commentsCount,
    setCommentsCount,
    imageDetailOpen,
    setImageDetailOpen,
    userId
  };
};