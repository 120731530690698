import React,{useState, useEffect} from 'react'
import { AppBar, Box, IconButton,Button,  Toolbar, Typography, Tabs, Tab, Fab, Divider, Avatar, Grid} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import TabPanel from '../../common/components/TabPanel';
import {icons} from '../../constants/icons';
import {formatTime} from '../../util/utils';
import {fetchBookmarkLists} from '../../repository/bookmarks/bookmarksRepository';
import BookmarkPostText from './BookmarkPostText';
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import GridBookmarkCard from '../../common/components/GridBookmarkCard';
// import {setPostGroup} from '../../repository/bookmarks/bookmarksSlice';

import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
  } from '@dnd-kit/core';
  import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';
  import {useSortable} from '@dnd-kit/sortable';
  import {CSS} from '@dnd-kit/utilities';
import BottomNav from '../../common/components/BottomNav';

const SortableItem = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        hi
      </div>
    );
  }

  const BookmarkPage = () => {
    return <Box>
      <BookmarkContent/>
      <Toolbar/>
      <BottomNav/>
    </Box>
  }
  
  const BookmarkContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);


    const searchParams = new URLSearchParams(window.location.search);
    const typeParam = searchParams.get('type') || 'post';
    const [currentTab, setCurrentTab] = useState(typeParam);

    const locationBookmarks = useSelector((state) => state.bookmarks.location);
    const postBookmarks = useSelector((state) => state.bookmarks.post_group);

    const [viewMode, setViewMode] = useState('list');
    const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9,10]);

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    useEffect(() => {
      window.scrollTo(0, 0); // 스크롤을 맨 위로 초기화
      fetchBookmarkLists(dispatch);
      console.log('BookmarkPage rendered');
    }, [dispatch]);
  
    function handleDragEnd(event) {
      const { active, over } = event;
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
  
    const handleClose = () => {
      navigate('/main');
    };
  
    const handleOrder = () => {
      setIsEditMode(true);
    };
  
    const handleDoneOrder = () => {
      setIsEditMode(false);
    };

  // 탭 변경 핸들러 수정
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(`/bookmarks?type=${newValue}`);
  };
  
  const handleMapView = () => {
    navigate('/bookmarks/map');
  };
  
    return (
      <Box>
        <AppBar position="static" sx={{ paddingLeft: '8px', margin: 0, backgroundColor: '#ffffff', elevation: 0, boxShadow: 'none' }}>
          <Toolbar sx={{ minHeight: '48px', height: '48px' }}>
            <IconButton onClick={handleClose} edge="start" color="inherit" aria-label="back">
              <img src="/assets/icons/back_button.png" alt="back_button" style={{ width: '10px', height: '17px' }} />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Typography sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', fontSize: '16px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.85)' }}>
              북마크
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={currentTab} 
            onChange={handleTabChange} 
            aria-label="simple tabs example"
            variant="fullWidth"
            sx={{
              '.MuiTabs-indicator': { backgroundColor: 'black', height: '2.5px' },
              '.Mui-selected': { color: 'rgba(0,0,0,0.9)' },
              '.MuiTab-root': { fontSize: '16px', fontWeight: 600 },
              '.MuiTab-root.Mui-disabled': { color: '#c4c4c4' },
            }}
          >
            <Tab label="장소" value="location"/>
            <Tab label="게시물" value="post"/>
          </Tabs>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: '16px' }}>
          {isEditMode ? (
            <Button onClick={handleDoneOrder} sx={{ fontWeight: 600, fontSize: '14px', p: 0, m: 0, color: 'rgba(58, 139, 255, 1)' }}>완료</Button>
          ) : (
            <Box />
          )}
          <Box sx={{ pr: '16px' }}>
            {/* <Button onClick={handleOrder}>
              <Box sx={{ display: 'flex', p: 0, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(115,115,115,1)' }}>순서편집</Typography>
                <img src="/assets/icons/change_order.png" alt="change_order" style={{ width: '16px', height: '16px' }} />
              </Box>
            </Button> */}
            { typeParam === 'post' && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton 
                  onClick={() => setViewMode('list')}
                sx={{ 
                  color: viewMode === 'list' ? 'primary.main' : 'text.secondary',
                  p: '4px'
                }}
              >
                <ViewListIcon />
              </IconButton>
              <IconButton 
                onClick={() => setViewMode('grid')}
                sx={{ 
                  color: viewMode === 'grid' ? 'primary.main' : 'text.secondary',
                  p: '4px'
                }}
              >
                <GridViewIcon />
              </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <TabPanel value={currentTab} index="location">
          {isEditMode && (
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map((id) => <SortableItem key={id} id={id} />)}
              </SortableContext>
            </DndContext>
          )}
          {locationBookmarks.map((locationInfo, index) => {
            const { location } = locationInfo;
            return <LocationBookmarkCard location={location} index={index} isEditMode={isEditMode} key={`location-bookmark-card-${index}`} />;
          })}
          <Fab 
            variant="extended"
            onClick={handleMapView}
            sx={{
                position: 'fixed',
                bottom: 75,  // 아래쪽에서 35px 떨어진 위치
                left: '50%', // 화면의 가운데
                transform: 'translateX(-50%)', // 완벽한 가운데 정렬을 위해 X축 기준 50% 이동
                borderRadius: '16px',
                border: '1px solid rgba(122,177,255,1)',
                backgroundColor: 'rgba(247,250,255,1)',
                boxShadow: '0px 0px 10px 0px rgba(194,208,227,0.5)'
            }}
            >
            <img src="/assets/icons/map.png" alt="map" style={{ width: '20px', height: '20px'}} />
            <Typography sx={{fontSize:'16px', fontWeight:600, color:'rgba(0,0,0,0.9)',paddingLeft:'4px'}}>지도 보기</Typography>
            </Fab>
        </TabPanel>
        <TabPanel value={currentTab} index="post">
          {isEditMode ? (
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {postBookmarks.map((postBookmark,index) => (
                  <PostBookmarkCard id={index} post={postBookmark} index={index} isEditMode={isEditMode} key={`post-bookmark-card-${index}`} />
                ))}
              </SortableContext>
            </DndContext>
          ) : (
            <Grid container spacing={1} sx={ viewMode === 'grid' ? { px:2 } : {}}>
              {postBookmarks.map((postBookmark, index) => (
                <Grid item xs={viewMode === 'grid' ? 4 : 12} key={postBookmark.id}>
                  {viewMode === 'grid' ? (
                    <GridBookmarkCard post={postBookmark} />
                  ) : (
                    <PostBookmarkCard id={index} post={postBookmark} index={index} isEditMode={isEditMode} key={`post-bookmark-card-${index}`} />
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>
      </Box>
    );
  };

const LocationBookmarkCard = ({location, index, isEditMode}) => {
    const navigate = useNavigate(); // useNavigate 훅 추가

    const handleClick = () => {
      if (!isEditMode) {
        navigate(`/bookmarks/map?index=${index}`);
      }
    };
    return <Box key={`location-bookmark-card-${index}`} onClick={handleClick} sx={{ cursor:'grab'}}>
        <Box sx={{ 
            display: 'flex', 
            padding: '20px',
            gap: '16px' // 아이콘과 텍스트 사이 간격 추가
        }}>
            <Box sx={{ 
                flexShrink: 0  // 박스가 줄어들지 않도록 설정
            }}>
                {isEditMode ?
                    <img src={'/assets/icons/list_handle.png'} alt="list_handle" style={{ width: '24px', height: '24px' }} />
                    : <img src={'/assets/icons/active/bookmark_pin.png'} alt="bookmark" style={{ width: '24px', height: '24px' }} />
                }
            </Box>
            <Box sx={{ flex: 1 }}>  {/* 남은 공간을 모두 차지하도록 설정 */}
                <Typography sx={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)', pb: '2px' }}>{location.name}</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'rgba(115, 115, 115, 1)', pb: '6px' }}>{location.address}</Typography>
            </Box>
        </Box>
        <Divider variant='middle' />
    </Box>
}

const PostBookmarkCard = ({id, post, index, isEditMode}) => {
    const navigate = useNavigate(); // useNavigate 훅 추가

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
      } = useSortable({id: id});
      
      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
      };
    const post_group = post?.post_group;

      // 클릭 핸들러 추가
    const handleClick = () => {
      if (!isEditMode && post_group) {
          navigate(`/posts/${post_group.id}`);
      }
    };
  
    return post_group && <div ref={setNodeRef} style={style} {...attributes} {...listeners} key={`post-bookmark-card-${index}`}>
        <Box onClick={handleClick}  sx={{ cursor: 'grab'}}>
    <Box sx={{display:'flex', padding:'16px 20px 0 20px', }}>
        <Box sx={{width:'48px'}}>
            {isEditMode ? 
                <img src={'/assets/icons/list_handle.png'} alt="list_handle" style={{ width: '24px', height: '24px'}} />
                : <img src={'/assets/icons/active/bookmark_mini.png'} alt="bookmark" style={{ width: '24px', height: '24px'}} />
            }
        </Box>
        <Box sx={{width:'100%'}}>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                <Box>
                    <Box sx={{display:'flex', alignItems:'center', pb:'4px'}}>
                        <Avatar sx={{width:'32px', height:'32px', mr:'8px'}}>
                            {post_group?.user.profile_image_url && 
                                <img 
                                    src={post_group.user.profile_image_url} 
                                    alt="profile_image" 
                                    style={{ width: '32px', height: '32px', borderRadius: '50%' }} 
                                />
                            }
                        </Avatar>
                        <Typography sx={{fontSize:'15px', fontWeight:700, color:'rgba(38, 38, 38, 1)'}}>{post_group?.user.username}</Typography>
                    </Box>
                    <Box sx={{fontSize:'14px', fontWeight:500, color:'rgba(38, 38, 38, 1)'}}>
                      <Box sx={{display:'flex', alignItems:'center', p:'4px 0 4px 16px'}}>
                        <Typography sx={{fontSize:'14px', fontWeight:500, color:'rgba(38, 38, 38, 1)'}}>
                          {post_group?.posts[0]?.art?.title} / {post_group?.posts[0]?.art?.artist}
                        </Typography>
                        </Box>
                        <Divider variant='middle'/>
                        <BookmarkPostText content={post_group?.posts[0].content} rating={0}/>
                    </Box>
                </Box>
                <Box sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Box sx={{width:'94px', height:'94px'}}>
                      {post_group?.posts[0].image_url ?
                          <img src={post_group?.posts[0].image_url} style={{width:'94px', height:'94px'}}/>
                          :<></>}
                  </Box> 
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pl: '8px',
                    pt: '20px'
                  }}>
                      <Typography sx={{fontSize:'12px', fontWeight:500, color:'rgba(115, 115, 115, 1)'}}>{formatTime(post_group?.created_at)}</Typography>
                  </Box>
                </Box>
            </Box>
        </Box>
    </Box>
    <Divider variant='middle'/>
</Box>
</div>
}

export default BookmarkPage;

