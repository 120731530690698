import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';


const commentStyle = {
    control: {
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(0,0,0,0.9)',
      position: 'relative',  // position 추가
      zIndex: 1, // 낮은 z-index 설정
      lineHeight: '1.5', // 줄간격 추가
      overflow: 'hidden', // 추가
    },
    input: {
      margin: 0,
      overflow: 'hidden', // auto에서 hidden으로 변경
      height: 'auto',
      padding: 0,
      outline: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      whiteSpace: 'pre-wrap', // 줄바꿈 처리
      wordBreak: 'break-word', // 단어 단위 줄바꿈
      lineHeight: 'inherit', 
      WebkitAppearance: 'none', // iOS 스크롤바 숨김
      '::-webkit-scrollbar': {  // 웹킷 스크롤바 숨김
        display: 'none'
      },
    },
    highlighter: {
      margin: 0,
      padding: 0,
      outline: 'none',
      border: 'none',
      zIndex: 2,
      color: '#1173FF',
      cursor: 'pointer',
    },
    suggestions: {
      list: {
        display: 'none', // 표시 중에는 제안 목록을 숨김
      },
    },
  };
  
const CommentContent = ({ content, mentionUsers }) => {
    return (
      <MentionsInput
        value={content}
        style={commentStyle}
        readOnly={true}
        singleLine={false}
      >
        <Mention
          trigger="@"
          data={mentionUsers}
          renderSuggestion={() => null}
          displayTransform={(id, display) => `@${display}`}
          markup="@[__display__](__id__)"
          // style={{ 
          //   color: '#1173FF',
          //   fontWeight: 500,
          //   backgroundColor: 'transparent',
          //   cursor: 'pointer'
          // }}
        />
      </MentionsInput>
    );
  };

  export default CommentContent;