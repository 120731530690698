import React from 'react';
import { Button } from '@mui/material';

const FollowButton = ({ onClick, is_follow }) => {
  return is_follow ?  <Button
  variant="contained"
  onClick={()=> onClick(is_follow)}
  sx={{
    backgroundColor: 'rgba(239, 243, 251, 1)',
    color: 'rgba(58, 139, 255, 1)',
    fontWeight: 600,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '6px 16px',
    height: '36px',
    boxShadow: 'none',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: 'rgba(229, 233, 241, 1)',
      boxShadow: 'none',
    },
  }}
>
  팔로잉 ✓
</Button> : <Button
  variant="contained"
  sx={{
    backgroundColor: '#3A8BFF',
    color: '#fff',
    borderRadius: '8px',
    height: '36px',
    fontSize: '14px',
    fontWeight: 600,
    boxShadow: 'none',
    padding: '6px 16px',
    whiteSpace: 'nowrap',
  }}
  onClick={()=> onClick(is_follow)} 
>
  팔로우
</Button>
}

export default FollowButton;