import { setComments, addComments, addReplies, addLikeComment, deleteLikeComment, deleteLikeReply, addLikeReply, deleteCommentById, deleteReplyById } from './commentsSlice';
import { getComments, postComments, postCommentLike, deleteCommentLike, deleteComment } from '../../api/SwissApi';
import { addCountCommentToFeed, addCountCommentToPopular, addCountCommentToDetail } from '../posts/postsSlice';
import { addCountCommentToProfile } from '../profiles/profilesSlice';

export const fetchPostComments = async (dispatch, postGroupId, bodyParam) => {
    try {
        const comment = await postComments(postGroupId, bodyParam);
        if (bodyParam.is_reply_to) {
            dispatch(addReplies(comment));
        } else {
            dispatch(addComments(comment));
        }

        dispatch(addCountCommentToFeed(postGroupId));
        // if(postType == "profile") {
            
        // } else if (postType == "profile") {
        //     dispatch(addCountCommentToProfile(postGroupId));
        // } else if (postType == "detail") {
        //     dispatch(addCountCommentToProfile(postGroupId));
        // } else if (postType == "popular") {
        //     dispatch(addCountCommentToPopular(postGroupId));
        // }

    } catch (error) {
        console.error('Failed to fetch bookmark lists:', error);
    }
};

export const fetchGetComments = async (dispatch, postGroupId) => {
    try {
        const comments = await getComments(postGroupId); 
        dispatch(setComments(comments));
    } catch (error) {
        console.error('Failed to fetch comments:', error);
    }
}

export const fetchPostCommentLike = async (dispatch, commentId) => {
    try {
        const response = await postCommentLike(commentId);
        dispatch(addLikeComment(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteCommentLike = async (dispatch, commentId) => {
    try {
        const response = await deleteCommentLike(commentId);
        dispatch(deleteLikeComment(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchPostReplyLike = async (dispatch, commentId, replyId) => {
    try {
        const response = await postCommentLike(replyId);
        dispatch(addLikeReply({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteReplyLike = async (dispatch, commentId, replyId) => {
    try {
        const response = await deleteCommentLike(replyId);
        dispatch(deleteLikeReply({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment like:', error);
    }
}

export const fetchDeleteComment = async (dispatch, postGroupId, commentId) => {
    try {
        const response = await deleteComment(postGroupId, commentId);
        dispatch(deleteCommentById(commentId));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment delete:', error);
    }
}

export const fetchDeleteReply = async (dispatch, postGroupId, commentId, replyId) => {
    try {
        const response = await deleteComment(postGroupId, replyId);
        dispatch(deleteReplyById({comment_id: commentId, reply_id: replyId}));
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch comment delete:', error);
    }
}