import React,{useState, useEffect} from 'react';
import { Box, Card, Typography, IconButton } from '@mui/material';
import { icons } from '../../constants/icons';
import {postBookmarks, deleteBookmarks} from '../../api/SwissApi';
import { useSelector } from 'react-redux';

import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';


const categorizePlaces = (types) => {
    if (types.includes('cafe')) {
      return '/assets/icons/place_cafe.png';
    } else if (types.includes('restaurant')) {
      return '/assets/icons/place_restaurant.png';
    } else if (types.includes('lodging')) {
      return '/assets/icons/place_hotel.png';
    } 
    return '/assets/icons/place_default.png';
  }

const LocationCard = ({location, handleClose, isLocationBookmarked, readOnly }) => {
    const { handleLoginAlert, addLocationBookmarkAlert, removeLocationBookmarkAlert } = useSnakeBarAlert();
    const { onMoveSearchPlacesMap } = useCustomNavigate();

    const [isPlaceBookmark, setIsPlaceBookmark] = useState(false);
    const userId = useSelector((state) => state.user.userId);

    useEffect(() => {
        setIsPlaceBookmark(isLocationBookmarked);
    },[isLocationBookmarked]);

    const handleLocationClick = () => {
      if (readOnly) {
        onMoveSearchPlacesMap(location);
      }
    };

    const handlePlaceBookmark = (location) => {
      if (!userId) {
        handleLoginAlert();
        return;
      }
      setIsPlaceBookmark(!isPlaceBookmark);
        if(!isPlaceBookmark){
          addLocationBookmarkAlert();
          postBookmarks('location', location.id).then(res => {
            console.log(res.status)
          })
        } else {
          removeLocationBookmarkAlert();
          deleteBookmarks('location', location.id).then(res => {
            console.log(res.status)
          })
        }
    
    }
    return (
      <Card sx={{ 
        boxShadow: 'none',
        p:0,
        margin:0, 
        border: 0,    
        '&:hover': {
          borderRadius: '12px', // Increase border radius on hover
        }
      }}> 
        <Box sx={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          p: '6px 12px',
          margin: 0,
          backgroundColor: 'rgba(245, 247, 248, 1)', 
          borderRadius: '12px',
          cursor: readOnly ? 'pointer' : 'default',
          minHeight: '52px',
          '& > *': {
            alignSelf: 'center'
          }
        }}>
          <Box sx={{ display: 'flex' }} onClick={handleLocationClick} >  
            <img src={categorizePlaces(location.types)} alt="category icon" style={{ width: 40, height: 40 }}/>
            <Box sx={{
              pl:'10px',
              width: 'calc(100% - 40px)', // IconButton 여백 조정
              overflow: 'hidden'
            }}> 
              <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(38, 38, 38, 1)' }}>
                {location.name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>  
                <img src='/assets/icons/location-14.png' alt='location' style={{ width: 14, height: 14, marginTop: '2px', marginRight: '3px' }}/>
                <Typography sx={{ 
                  fontSize: '12px', 
                  fontWeight: 400, 
                  color: 'rgba(38, 38, 38, 1)', 
                  flexGrow: 1,
                  wordBreak: 'break-word',
                  paddingRight: '8px' // IconButton과의 간격 살짝 증가
                }}>
                  {location.address}
                </Typography>
              </Box>
            </Box>
          </Box>
          {readOnly ? 
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              height: '100%'
            }}>
              <IconButton 
                edge="start" 
                onClick={()=>{handlePlaceBookmark(location)}} 
                aria-label="placeBookmark" 
                sx={{ 
                  width:'34px', 
                  height:'34px', 
                  color: 'black', 
                  backgroundColor:'rgba(255,255,255,1)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                  margin: 0
                }}>
                <img src={isPlaceBookmark ? icons.placeBookmark.active : icons.placeBookmark.inactive} alt='bookmark' style={{ width: 22, height: 22}}/>
              </IconButton>
            </Box>
          :
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" sx={{ color: 'black' }}>
            <img src='/assets/icons/close.png' alt='close' style={{ width: 22, height: 22}}/>
          </IconButton>
          
          }
          </Box>
      </Card>
    );
  };

  export default LocationCard;