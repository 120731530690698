import { createSlice } from '@reduxjs/toolkit';

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState: {
    posts: [],
    user: {},
    follower_count:0,
    following_count:0,
    is_follow:false,
    followers:[],
    followings:[],
  },
  reducers: {
    setProfile: (state, action) => {
      state.posts = action.payload.posts_group;
      state.user = action.payload.user;
      state.follower_count = action.payload.followed_count;
      state.following_count = action.payload.follower_count;
      state.is_follow = action.payload.is_follow;
    },
    setFollowing: (state, action) => {
      state.followings = action.payload.results;
    },

    setFollower: (state, action) => {
      state.followers = action.payload.results;
    },

    addFollower: (state, action) => {
      state.is_follow = true;
      state.following_count += 1;
    },
    addCountCommentToProfile: (state, action) => {
      const index = state.posts.findIndex(post => post.id === action.payload);
      if (index !== -1) {
        state.posts[index].comments_count++;
      }
    },
    updateProfilePostById: (state, action) => {
      const { id, content, location, rating, is_liked } = action.payload;

      // 해당 ID를 가진 게시글의 인덱스를 찾습니다.
      const index = state.posts.findIndex(post => post.id === id);

      // 인덱스 검사: 배열 범위 내에 있는지 확인합니다.
      if (index !== -1) {
          const post = state.posts[index];

          if (content !== undefined) {
              post.content = content;
          }
          if (location !== undefined) {
              post.location = { ...post.location, ...location };
          }
          if (rating !== undefined) {
              post.rating = rating;
          }
          if (is_liked !== undefined) {
              // 좋아요 상태가 변경되면 좋아요 수도 조정합니다.
              post.is_liked = is_liked;
              if (is_liked) {
                  post.likes_count++;
              } else if (post.likes_count > 0) {
                  post.likes_count--;
              }
          }
      } else {
          console.error("No posts found with id: ", id);
      }
    },
    removeFollower: (state, action) => {
      state.is_follow = false;
      state.following_count -= 1;
    },
    updateAddFollower: (state, action) => {
      const existingFollower = state.followers.find(
        follower => follower.user.id === action.payload.user.id
      );      
      if (existingFollower) {
        existingFollower.is_follow = action.payload.is_follow;
        state.following_count += 1;
      }
    },
    updateDeleteFollower: (state, action) => {
      const existingFollower = state.followers.find(
        follower => follower.user.id === action.payload
      );      
      if (existingFollower) {
        existingFollower.is_follow = false;
        state.following_count -= 1;
      }
    },
    updateAddFollowing: (state, action) => {
      const existingFollowing = state.followings.find(
        following => following.user.id === action.payload
      );      
      if (existingFollowing) {
        existingFollowing.is_follow = true;
        state.following_count += 1;
      }
    },
    updateDeleteFollowing: (state, action) => {
      const existingFollowing = state.followings.find(
        following => following.user.id === action.payload
      );      
      if (existingFollowing) {
        existingFollowing.is_follow = false;
        state.following_count -= 1;
      }
    },

    setIsFollow: (state, action) => {
      state.is_follow = action.payload;
    },
    deleteProfilePostById: (state, action) => {
      state.posts = state.posts.filter(post => post.id !== action.payload);
    }
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setFollower, setFollowing, setIsFollow, addFollower, deleteFollower, removeFollower, addCountCommentToProfile, updateProfilePostById, updateAddFollower, updateDeleteFollower, updateAddFollowing, updateDeleteFollowing, deleteProfilePostById } = profilesSlice.actions;

export default profilesSlice.reducer;
