import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Button, Tooltip  } from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';  // 음성 설명 아이콘
import PostImageDetail from './PostImageDetail';

const TitleContainer = styled(Box)({
    padding: '4px 8px',
    borderBottom: '1px solid #aaaaaa',
    textAlign: 'left',
    width: '100%',  // width 100% 추가
    flex: 1        // flex-grow를 1로 설정하여 남은 공간을 모두 차지하도록 함
});
  
  const Title = styled(Typography)({
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
    marginBottom: '0', // 하단 여백 추가
    '& span': {
      margin: '0 2px',  // 슬래시 좌우 여백 축소
      color: '#666'
    }
  });
  
  function ArtTextComponent({ groupId, title, artist, rating, selectedImage }) {
    if (!title?.trim() || !artist?.trim()) {
        return <></>;
    }

    return (
    <Box sx={{
        display: 'flex', 
        pl:2,
        pr:2,
        pt:1,
        pb:1
    }}>
        { rating > 0 && 
          <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
              <img 
              src={'/assets/icons/star.png'} 
              alt="rating" 
              style={{ 
                  display: 'inline', 
                  width: 14, 
                  height: 14,
                  marginRight: '2px'  // 별점과 숫자 사이 간격 추가
              }}
              />
              <span style={{ 
              fontSize: '14px', 
              fontWeight: 600, 
              color: 'rgba(0, 0, 0, 1)', 
              verticalAlign: 'baseline'
              }}>
              {rating && rating > 0 ? rating.toFixed(1) : '-'}
              </span>
          </span>
        }
        <TitleContainer>
            <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between' // 양쪽 정렬을 위해 추가
            }}>
            <Title>
            {title} <span>/</span> {artist}
            </Title>
            <AIDocent selectedImage={selectedImage} groupId={groupId} title={title} artist={artist}/>
            </Box>
        </TitleContainer>
    </Box>
    );
  }
  

  const AIDocent = ({ groupId, selectedImage, title, artist}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false); // 툴팁 상태 추가

    const handleClick = () => {
      setDrawerOpen(true);
      setTooltipOpen(false); // 클릭시 툴팁 닫기
    }

    return (
        <>
            <Tooltip title="AI 해설" open={tooltipOpen}>
                <Button variant="outlined" sx={{
                    borderColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '10px',
                    minWidth: '40px',
                    height: '30px',
                    fontSize: '12px',
                }} onClick={handleClick}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                >
                    <Typography sx={{
                        color: 'rgba(0,0,0,0.5)', 
                        fontSize: '12px', 
                        fontWeight: 600,
                        whiteSpace: 'nowrap'  // 한 줄로 표시
                    }}>AI 해설</Typography>
                </Button>
            </Tooltip>
            {drawerOpen && <PostImageDetail selectedImage={selectedImage} modalOpen={drawerOpen} setModalOpen={setDrawerOpen} isDocent={true} groupId={groupId} title={title} artist={artist}/>}
        </>
    );
  }

  export default ArtTextComponent;
