import { getPostDetail, getFeedList, getMoreFeedList, getPostPopular, postFeed, postFeedLike, deleteFeedLike, deletePosts } from '../../api/SwissApi';
import { setPostDetail, setFeedList, moreFeedList, setPopular, morePopular, clearEditors, updateFeedById, deleteFeedById } from './postsSlice';
import { updateProfilePostById, deleteProfilePostById } from '../profiles/profilesSlice';

export const fetchPostFeed = async (dispatch, feed) => {
    try {
        const response = await postFeed(feed);
        console.log(response);
        dispatch(clearEditors());
    } catch (error) {
        console.error('Failed to fetch feed:', error);
    }
}

export const fetchGetFeedDetail = async (dispatch, postGroupId) => {
    try {
        const response = await getPostDetail(postGroupId);
        if(response.success) {
            dispatch(setPostDetail(response.data));
        }
        return response;
    } catch (error) {
        console.error('Failed to fetch post detail:', error);
    }
};

export const fetchFeedList = async (dispatch, type='feed') => {
    try {
        const feedList = await getFeedList(type);
        dispatch(setFeedList(feedList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}
export const fetchMoreFeedList = async (dispatch, nextUrl) => {
    try {
        const feedList = await getMoreFeedList(nextUrl);
        dispatch(moreFeedList(feedList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchPostPopular = async (dispatch) => {
    try {
        const postPopularList = await getPostPopular();
        dispatch(setPopular(postPopularList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchMorePostPopular = async (dispatch, nextUrl) => {
    try {
        const postPopularList = await getMoreFeedList(nextUrl);
        dispatch(morePopular(postPopularList));

    } catch (error) {
        console.error('Failed to fetch feed list:', error);
    }
}

export const fetchPostFeedLike = async (dispatch, postId) => {
    try {
        const response = await postFeedLike(postId);
        console.log(response);
        dispatch(updateFeedById({ id: postId, is_liked: true })) 
        //dispatch(updateProfilePostById({ id: postId, is_liked: true }));
    } catch (error) {
        console.error('Failed to fetch feed like:', error);
    }
}

export const fetchDeleteFeedLike = async (dispatch, postId) => {
    try {
        const response = await deleteFeedLike(postId);
        console.log(response);
        dispatch(updateFeedById({ id: postId, is_liked: false })) 
        //dispatch(updateProfilePostById({ id: postId, is_liked: false }));

    } catch (error) {
        console.error('Failed to fetch feed like:', error);
    }
}


export const fetchDeletePost = async (dispatch, postId) => {
    try {
        const response = await deletePosts(postId);
        dispatch(deleteFeedById(postId))
        dispatch(deleteProfilePostById(postId))
        console.log(response);
    } catch (error) {
        console.error('Failed to fetch feed like:', error);
    }
}
// export const fetchAddLocation = async (dispatch, location) => {
//     try {
//         const response = await addLocation(location);
//         dispatch(updateEditorAtIndex({
//             index: contentIndex || 0, 
//             location: {
//               name: result.name,
//               address: result.formatted_address,
//               types: result.types,
//               latitude: result.geometry.location.lat,
//               longitude: result.geometry.location.lng,
//             },
//         }));
//     } catch (error) {
//         console.error('Failed to fetch add location:', error);
//     }
// }