import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HandleRedirectPage from './features/users/HandleRedirectPage'; // Ensure this import points to your component's file location
import HomePage from './HomePage'; // Another component for the home page
import LoginPage from './features/users/LoginPage'; // Component for the login page
import { useDispatch, useSelector } from 'react-redux';
import RestaurantReviewForm from './features/posts/RestaurantReviewForm';
import ProfilePage from './features/profile/ProfilePage';
import ContentEditForm from './features/posts/ContentEditForm';
import AccommodationReviewForm from './features/posts/AccommodationReviewForm';
import TouristReviewForm from './features/posts/TouristReviewForm';
import ContentManagePage from './features/admin/ContentManagePage';
import MainPage from './MainPage';
import PlaceSearch from './common/components/PlaceSearch';
import PlaceSearchGoogle from './common/components/PlaceSearchGoogle';
import PlaceSearchMap from './common/components/PlaceSearchMap';
import SearchPage from './features/search/SearchPage';
import BookmarkPage from './features/bookmark/BookmarkPage';
import Dndtest from './Dndtest';
import QuestionEditForm from './features/posts/QuestionEditForm';
import ProtectedRoute from './ProtectedRoute';  // ProtectedRoute import
import UnprojectedRoute from './UnprojectedRoute';
import PostDetailPage from './features/posts/detail/PostDetailPage';
import { addAlert } from './repository/alert/alertSlice';
import OwnerProtectedRoute from './OwnerProtectedRoute';
import EditProfilePage from './features/profile/EditProfilePage';
import SettingPage from './features/profile/SettingPage';
import PlaceMap from './common/components/PlaceMap';
import BookmarkMap from './common/components/BookmarkMap';
import ManualLoginPage from './features/users/ManualLoginPage'; // ManualLoginPage 컴포넌트 import 추가
import ReactGA from "react-ga4";


ReactGA.initialize("G-HBTTG5EX51"); // Google Analytics Tracking ID

function App() {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);

    useEffect(() => {
        ReactGA.send("pageview");
    }, []);
    // SSE Server와 연결
    useEffect(() => {
        if (!userId) return;

        const eventSource = new EventSource(`${process.env.REACT_APP_SSE_SERVER_URI}/sse/connect?userId=${userId}`); 

        // 연결 성공 시 호출되는 onopen 이벤트
        eventSource.onopen = () => {
            console.log('SSE 연결이 성공했습니다.');
        };

        console.log('EventSource 초기화:', eventSource);
        eventSource.onmessage = (event) => {
            const newNotification = JSON.parse(event.data);
            dispatch(addAlert(newNotification));
        };

        eventSource.onerror = (error) => {
            console.error('SSE Error:', error);
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    }, [userId, dispatch]);

  return (
      <Router>
          <Routes>
              <Route path="/" element={<UnprojectedRoute><MainPage /></UnprojectedRoute>} />
              <Route path="/main" element={<UnprojectedRoute><MainPage /></UnprojectedRoute>} />
              <Route path="/main2" element={<HomePage />} />
              <Route path="/search" element={<UnprojectedRoute><SearchPage /></UnprojectedRoute>} />
              <Route path="/search/places" element={<UnprojectedRoute><PlaceSearch /></UnprojectedRoute>} />
              <Route path="/search/places/map" element={<UnprojectedRoute><PlaceMap /></UnprojectedRoute>} />
              <Route path="/search/places/google" element={<UnprojectedRoute><PlaceSearchGoogle /></UnprojectedRoute>} />
              <Route path="/search/places/google/map" element={<UnprojectedRoute><PlaceSearchMap /></UnprojectedRoute>} />

              {/* 보호된 라우트 */}
              <Route path="/posts/:post_group_id" element={<UnprojectedRoute><PostDetailPage/></UnprojectedRoute>} />
              <Route path="/posts/contents/edit" element={<ProtectedRoute><ContentEditForm /></ProtectedRoute>} />
              <Route path="/posts/questions/edit" element={<ProtectedRoute><QuestionEditForm /></ProtectedRoute>} />
              <Route path="/reviews/restaurant" element={<ProtectedRoute><RestaurantReviewForm /></ProtectedRoute>} />
              <Route path="/reviews/travel" element={<ProtectedRoute><ContentEditForm /></ProtectedRoute>} />
              <Route path="/reviews/accommodation" element={<ProtectedRoute><AccommodationReviewForm /></ProtectedRoute>} />
              <Route path="/reviews/tourist" element={<ProtectedRoute><TouristReviewForm /></ProtectedRoute>} />
              <Route path="/bookmarks" element={<ProtectedRoute><BookmarkPage /></ProtectedRoute>} />
              <Route path="/bookmarks/map" element={<ProtectedRoute><BookmarkMap /></ProtectedRoute>} />
              <Route path="/profiles" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
              <Route path="/profiles/:user_id/edit" 
                element={
                    <ProtectedRoute>
                    <OwnerProtectedRoute>
                        <EditProfilePage/>
                    </OwnerProtectedRoute>
                    </ProtectedRoute>
                } 
              />
              <Route path="/profiles/:user_id/setting" 
                element={
                <ProtectedRoute>
                    <OwnerProtectedRoute>
                        <SettingPage />
                    </OwnerProtectedRoute>
                </ProtectedRoute>} 
            />
              <Route path="/admin/manage/contents" element={<ProtectedRoute><ContentManagePage /></ProtectedRoute>} />

              <Route path="/profiles/:user_id" element={<UnprojectedRoute><ProfilePage /></UnprojectedRoute>}/>

              {/* 로그인 페이지는 보호되지 않음 */}
              <Route path="/manual/login" element={<ManualLoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/redirect/:social" element={<HandleRedirectPage />} />
              <Route path="/test/dnd" element={<Dndtest />} />
              {/* Add other routes here */}
          </Routes>
      </Router>
  );
}

export default App;