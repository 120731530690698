import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messagesByGroupId: {}, // { groupId: { messages: [], typingMessageId: null, isLoading: false } }
};

const docentSlice = createSlice({
  name: 'docent',
  initialState,
  reducers: {
    initializeIfNeeded: (state, action) => {
      const { groupId, artist, title } = action.payload;
      if (!state.messagesByGroupId[groupId]) {
        state.messagesByGroupId[groupId] = {
          messages: [
            { isAI: true, message: `${artist}의 '${title}' 작품을 설명해드릴게요!`, isFirstMessage: true }
          ],
          typingMessageId: null,
          isLoading: false
        };
      }
    },
    initializeMessages: (state, action) => {
      const { groupId, artist, title } = action.payload;
      state.messagesByGroupId[groupId] = {
        messages: [
          { isAI: true, message: `${artist}의 '${title}' 작품을 설명해드릴게요!`, isFirstMessage: true }
        ],
        typingMessageId: null,
        isLoading: false
      };
    },
    addMessage: (state, action) => {
      const { groupId, message } = action.payload;
      if (!state.messagesByGroupId[groupId]) {
        state.messagesByGroupId[groupId] = { messages: [], typingMessageId: null, isLoading: false };
      }
      state.messagesByGroupId[groupId].messages.push(message);
    },
    updateMessage: (state, action) => {
      const { groupId, id, displayMessage } = action.payload;
      const groupMessages = state.messagesByGroupId[groupId]?.messages;
      if (groupMessages) {
        const messageIndex = groupMessages.findIndex(msg => msg.id === id);
        if (messageIndex !== -1) {
          groupMessages[messageIndex].displayMessage = displayMessage;
        }
      }
    },
    setTypingMessageId: (state, action) => {
      const { groupId, id } = action.payload;
      if (state.messagesByGroupId[groupId]) {
        state.messagesByGroupId[groupId].typingMessageId = id;
      }
    },
    setLoading: (state, action) => {
      const { groupId, isLoading } = action.payload;
      if (state.messagesByGroupId[groupId]) {
        state.messagesByGroupId[groupId].isLoading = isLoading;
      }
    },
    removeLoadingMessage: (state, action) => {
      const { groupId } = action.payload;
      if (state.messagesByGroupId[groupId]) {
        state.messagesByGroupId[groupId].messages = 
          state.messagesByGroupId[groupId].messages.filter(msg => msg.id !== 'loading');
      }
    }
  }
});

// Selectors
export const selectGroupMessages = (state, groupId) => 
  state.docent.messagesByGroupId[groupId]?.messages || [];

export const selectGroupTypingMessageId = (state, groupId) => 
  state.docent.messagesByGroupId[groupId]?.typingMessageId;

export const selectGroupIsLoading = (state, groupId) => 
  state.docent.messagesByGroupId[groupId]?.isLoading || false;

export const { 
  initializeIfNeeded,
  initializeMessages, 
  addMessage, 
  updateMessage, 
  setTypingMessageId, 
  setLoading,
  removeLoadingMessage 
} = docentSlice.actions;

export default docentSlice.reducer;