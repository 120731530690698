import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, IconButton, Avatar, Box, Divider, Menu, MenuItem, Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { styled } from '@mui/material/styles';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import PostCardText from './PostCardText';
import CommentDrawer from '../../features/comment/CommentDrawer';
import LocationCard from './LocationCard';

import {icons} from '../../constants/icons';
import {formatTime} from '../../util/utils';
import CustomSnackbar from './exception/CustomSnackbar';
import ArtTextComponent from './ArtTextComponent';
import PostImageDetail from './PostImageDetail';
import { usePostInteractions } from '../../features/posts/hooks/usePostInteractions';
import { useCustomNavigate } from '../../common/hooks/useCustomNavigate';
import { useModal } from '../../common/hooks/useModal';
import { useComment } from '../../features/comment/hooks/useComment';
import { useSnakeBarAlert } from '../../common/hooks/useSnakeBarAlert';
import { initKakao } from 'kakao-js-sdk';
import { fetchDeletePost } from '../../repository/posts/postsRepository';
import { useDispatch } from 'react-redux';

const CustomSwiper = styled(Swiper)({
  '& .swiper-pagination': {
    left: 0,
    right: 0,
    top: '380px',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none', // 페이지네이션 요소에 대한 포인터 이벤트 비활성화
    '& .swiper-pagination-bullet': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      pointerEvents: 'auto' // 페이지네이션 버튼은 클릭 가능하게 설정
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: '#FFFFFF',
    }
  },
});

const StyledSwiper = {
  height: '400px',
  paddingTop: '12px',
  // borderRadius: '30px', // Rounded corners with 8px radius
};
function PostCard({ post }) {
  const {
    swiperRef,
    currentSlide,
    setCurrentSlide,
    isLiked,
    isPostBookmarked,
    handleLike,
    handleBookmark,
    likesCount,
    commentsCount,
    setCommentsCount,
    userId
  } = usePostInteractions(post);

  const { onMoveProfiles } = useCustomNavigate();
  const { selectedImage, handleImageDetailClick, openImageDetail, setOpenImageDetail } = useModal();
  const { openCommentDrawer, drawerOpen, closeCommentDrawer } = useComment();
  const { exceptionShareAlert } = useSnakeBarAlert();
  // Web Share API를 사용하는 공유 함수

  function shareFallback() {
    initKakao(`${process.env.REACT_APP_KAKAO_APP_KEY}`).then((isloaded) => {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: '[아트프렌즈] 내 하루의 예술 한 스푼',
          description: post.posts[currentSlide].art.title + ' / ' + post.posts[currentSlide].art.artist,
          imageUrl: post.posts[currentSlide].image_url,
          link: {
            mobileWebUrl: `${window.location.origin}/posts/${post.id}`,
            webUrl: `${window.location.origin}/posts/${post.id}`,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: `${window.location.origin}/posts/${post.id}`,
              webUrl: `${window.location.origin}/posts/${post.id}`,
            },
          },
        ],
      });
    })
    
  }

  const handleShare = () => {
    if (navigator.share) {
      navigator
          .share({
            title: post.title || '[아트프렌즈] 내 하루의 예술 한 스푼',
            text: post.posts[currentSlide].art.title + ' / ' + post.posts[currentSlide].art.artist,
            url: `${window.location.origin}/posts/${post.id}`, // 현재 페이지 URL 공유
          })
          .then(() => {
            console.log('공유 성공');
          })
          .catch((error) => {
            console.log('공유 실패', error);
          });
      } else {
        // exceptionShareAlert();
        shareFallback();
      }
  };

  return (
    <>
    <Card key={`postcard-${post.id}`} sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: 'none'}} >
      <PostCardHeader user={post.user} userId={userId} groupId={post.id} createdAt={post.created_at} onMoveProfiles={onMoveProfiles}/>
      <CardContent sx={{ padding: 0 }}>
        <Box>
          <CustomSwiper
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={0}
          centeredSlides={false}
          pagination={{
            clickable: true,
          }}
          autoHeight={true}
          onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
          modules={[Pagination]}
          className="postSwiper">
        {post?.posts?.map((postInfo, index) => {
          return (
            <SwiperSlide key={`slide-${postInfo.id}-${index}`}>
              {
                postInfo.image_url && postInfo.image_url !== "" && 
                <img src={postInfo.image_url} style={{...StyledSwiper, cursor: 'pointer'}} onClick={() => handleImageDetailClick(postInfo.image_url)}/>
              }
              {
                post.posts[currentSlide].art &&     
                <ArtTextComponent groupId={post.id} title={post.posts[currentSlide].art.title} artist={post.posts[currentSlide].art.artist} rating={postInfo.rating} selectedImage={postInfo.image_url}/>
              }
              <PostCardText content={postInfo.content} rich_content={postInfo.rich_content} hashtags={postInfo.hashtags} swiperRef={swiperRef}/>
            </SwiperSlide>
          );
        })}  
      </CustomSwiper>
      {
        post.posts && 
        post.posts[currentSlide] && 
        post.posts[currentSlide].location &&
        <Box sx={{pl:2, pr:2, pb:'10px'}}>
          <LocationCard location={post.posts[currentSlide].location} readOnly={true} isLocationBookmarked={post.posts[currentSlide].is_locations_bookmarked}/>
        </Box>
      }
        </Box>
      </CardContent>
      <PostCardActions 
        isLiked={isLiked}
        likesCount={likesCount}
        commentsCount={commentsCount}
        isBookmarked={isPostBookmarked}
        onLike={handleLike}
        onComment={openCommentDrawer}
        onShare={handleShare}
        onBookmark={handleBookmark}
      />
    <CustomSnackbar autoHideDuration={3000}/>
    <Divider variant="middle" sx={{borderBottomWidth: '2px' }}/>
    <CommentDrawer postGroupId={post.id} open={drawerOpen} handleClose={closeCommentDrawer} setCommentsCount={setCommentsCount}/>
    </Card>
    <PostImageDetail selectedImage={selectedImage} modalOpen={openImageDetail} setModalOpen={setOpenImageDetail}/>
    </>
  );
}

export default PostCard;


const PostCardHeader = ({ user, userId, groupId, createdAt, onMoveProfiles }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const { removePostAlert } = useSnakeBarAlert();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
  };

  const handleDelete = () => {
      setOpenDialog(true);
      handleMenuClose();
  };

  const handleConfirmDelete = async () => {
      // TODO: 삭제 로직 구현
      await fetchDeletePost(dispatch, groupId);
      removePostAlert();
      // await fetchDeleteReply(dispatch, reply.group_id, commentId, reply.id);
      setOpenDialog(false);
  };

  const handleCancelDelete = () => {
      setOpenDialog(false);
  };

  return <Box sx={{ p: 2, pb: '0px',display: 'flex', justifyContent: 'space-between'}}>
    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} 
         onClick={() => onMoveProfiles(user)}>
      <Avatar src={user?.profile_image_url} 
              sx={{ width: 44, height: 44, marginRight: 1.5 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
          {user?.username}
        </Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 400, color: '#737373' }}>
          {formatTime(createdAt)}
        </Typography>
      </Box>
    </Box>
    {userId == user.id &&
    <Box>
      <IconButton 
          sx={{ p: 0, m: 0 }}
          onClick={handleMenuClick}
      >
        <img src={'/assets/icons/comments/more.png'} alt="more" style={{ width: 20, height: 20 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDelete} sx={{p:2}}>
          <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'red',}}>
            삭제하기
          </Typography>
        </MenuItem>
      </Menu> 
      <Dialog
          open={openDialog}
          onClose={handleCancelDelete}
      >
          <DialogTitle sx={{ fontSize: '14px', py: 2 }}>
              정말 삭제하시겠습니까?
          </DialogTitle>
          <DialogActions sx={{ pb: 2, px: 2 }}>
              <Button onClick={handleCancelDelete} sx={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                  취소
              </Button>
              <Button onClick={handleConfirmDelete} sx={{ color: 'red' }}>
                  삭제
              </Button>
          </DialogActions>
      </Dialog>
    </Box>
    }
  </Box>;
};

const PostCardActions = ({ 
  isLiked, 
  likesCount, 
  commentsCount, 
  isBookmarked,
  onLike,
  onComment,
  onShare,
  onBookmark 
}) => (
  <CardActions sx={{ mt: 'auto', justifyContent: 'space-between', pt:0 }} 
               disableSpacing>
    <Box sx={{ display: 'flex' }}> 
      <IconButton onClick={onLike}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={isLiked ? icons.like.active : icons.like.inactive} 
               alt="Like" 
               style={{ width: 20, height: 20 }} />
          <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
            {likesCount || ''}
          </Typography>
        </Box>
      </IconButton>
      <IconButton aria-label="comment" onClick={onComment}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={icons.comments.active} alt="Comment" style={{ width: 20, height: 20 }} />
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)',pl:'6px' }}>
            {commentsCount || '' } {/* 댓글 수 */}
           </Typography>
          </Box>
      </IconButton>
    </Box>
    <Box sx={{ display: 'flex' }}>
        <IconButton aria-label="share" sx={{ marginRight: '6px' }} onClick={onShare}>
          <img src={icons.share.active} alt="Share" style={{ width: 20, height: 20 }} />
        </IconButton>
        <IconButton onClick={onBookmark} aria-label="bookmark">
          <img src={isBookmarked ? icons.smallBookmarks.active : icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 20, height: 20 }} />
        </IconButton>
    </Box>
  </CardActions>
);