import React from 'react';
import { Card, CardContent, CardActions, Box, Divider, IconButton, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import {icons} from '../../../constants/icons';
function PostCardSkeleton() {
  return (
    
    <Card sx={{ display: 'flex', flexDirection: 'column', border: 'none', boxShadow: 'none' }}>
      {/* Header Skeleton */}
      <Box sx={{ p: 2, pb: '0px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="circular" width={44} height={44} sx={{ marginRight: 1.5 }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={80} height={20} />
          </Box>
        </Box>
      </Box>

      {/* Content Skeleton */}
      <CardContent sx={{ padding: 0 }}>
        <Box>
          {/* Image Skeleton */}
          <Skeleton 
            variant="rectangular" 
            width="100%" 
            height={400}
            sx={{ paddingTop: '12px' }}
          />
          
          {/* Art Text Component Skeleton */}
          <Box sx={{ p: 2 }}>
            <Skeleton variant="text" width="60%" height={24} />
          </Box>
          <Divider variant="middle" sx={{ borderBottomWidth: '2px' }} />


          {/* Post Text Skeleton */}
          <Box sx={{ p: 2 }}>
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="80%" height={20} />
          </Box>

          {/* Location Card Skeleton */}
          {/* <Box sx={{ pl: 2, pr: 2, pb: '10px' }}>
            <Skeleton variant="rectangular" width="100%" height={80} />
          </Box> */}
        </Box>
      </CardContent>

      {/* Actions Skeleton */}
      <CardActions sx={{ mt: 'auto', justifyContent: 'space-between', pt:0 }} disableSpacing>
        <Box sx={{ display: 'flex' }}> 
        <IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={icons.like.inactive} 
                alt="Like" 
                style={{ width: 20, height: 20 }} />
            <Typography sx={{fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)', marginLeft: 1}}>
                {''}
            </Typography>
            </Box>
        </IconButton>
        <IconButton aria-label="comment">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={icons.comments.active} alt="Comment" style={{ width: 20, height: 20 }} />
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)',pl:'6px' }}>
                {''} {/* 댓글 수 */}
            </Typography>
            </Box>
        </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="share" sx={{ marginRight: '6px' }}>
            <img src={icons.share.active} alt="Share" style={{ width: 20, height: 20 }} />
            </IconButton>
            <IconButton aria-label="bookmark">
            <img src={icons.smallBookmarks.inactive} alt="Bookmark" style={{ width: 20, height: 20 }} />
            </IconButton>
        </Box>
    </CardActions>

      <Divider variant="middle" sx={{ borderBottomWidth: '2px' }} />
    </Card>
  );
}

export default PostCardSkeleton;
