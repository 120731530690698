// CustomSnackbar.js
import React from 'react';
import { Snackbar, IconButton, Button, Typography  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSnakeBarAlert } from '../../hooks/useSnakeBarAlert';

const StyledSnackbar = styled(Snackbar)({
  '& .MuiPaper-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: 'white',
    borderRadius: '8px',
    height: '48px',
    fontWeight: 600,
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
    opacity: 0.75,
    zIndex: 10000,
  }
});

const CustomSnackbar = ({ autoHideDuration = 3000 }) => {
  const navigate = useNavigate();

  const { snackbarState, handleCloseSnackbar } = useSnakeBarAlert();

  const handleActionClick = (e) => {
    e.stopPropagation();  // 이벤트 전파 중단
    if (snackbarState.actionRoute) {
      navigate(snackbarState.actionRoute);
    }
    handleCloseSnackbar();
  };


  const LoginButton = () => {
    return (
      <Button
        variant="text"
        sx={{
          padding: 0,
          minWidth: 'auto',
          color: 'white',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
          cursor: 'pointer',  // 명시적으로 커서 스타일 추가
          touchAction: 'manipulation',  // 터치 동작 최적화
        }}
        onClick={handleActionClick}
        onTouchEnd={handleActionClick}  // 터치 이벤트 추가
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: '#FFD058', 
            fontWeight: '500',
            textDecoration: 'underline',
            textUnderlineOffset: '3px',
          }}
        >
          로그인
        </Typography>
      </Button>
    );
  }

  return (
    <StyledSnackbar
      open={snackbarState.open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleCloseSnackbar}
      message={snackbarState.message}
      sx={{ bottom: '75px', 
        marginLeft: '10px', 
        marginRight: '10px',        
        bottom: '75px',
      }}
      action={
        snackbarState.actionIcon && (
          <React.Fragment>
            {snackbarState.actionRoute == '/login' ? <LoginButton/> : <IconButton
              aria-label="action"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleActionClick}
              onTouchEnd={handleActionClick}  // 터치 이벤트 추가
            >
              <img src={snackbarState.actionIcon} alt="Action icon" />
            </IconButton>}
          </React.Fragment>
        )
      }
    />
  );
};

export default CustomSnackbar;
